
import { Options, Vue } from "vue-class-component";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { SHIPMENT_STT_BOOKING } from "@/app/infrastructures/misc/RolePermission";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { FlagsPermissionBooking } from "@/feature-flags/flag-booking";
import ModalInstantBoookingConfirmation from "../../views/shipment/booking/component/modal-instant-booking-confirmation.vue";
import { InstantBookingController } from "../../controllers/InstantBookingController";

@Options({
  components: {
    ModalInstantBoookingConfirmation
  }
})
export default class FloatingBooking extends Vue {
  float = true;
  menuFloat = false;
  tncModal = false;
  toggleTooltip() {
    this.menuFloat = !this.menuFloat;
    dataLayer("floating_button_booking_sekarang", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }
  closeFloat() {
    this.float = !this.float;
    dataLayer("floating_button_booking_close", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }
  get menus() {
    const permissions = [
      {
        status: FlagsPermissionBooking.permission_booking_create_manual.isEnabled(),
        title:
          this.dataProfile.account_type === "client"
            ? "Booking Klien"
            : "Booking Retail",
        icon: "booking-retail",
        action: this.validationAccount ? this.goToCreate : {}
      },
      {
        status:
          FlagsPermissionBooking.permission_booking_create_for_corporate.isEnabled() && !this.dataProfile.isPosChildAccount,
        title: "Booking Klien",
        icon: "booking-client",
        action: this.validationAccount ? this.goToCreateClientBook : {}
      },
      {
        status: FlagsPermissionBooking.permission_booking_create_shipment_id.isEnabled(),
        title: "Booking Aplikasi & Marketplace",
        icon: "booking-app",
        action: this.validationAccount ? this.goToCreateShipmentBook : {}
      },
      {
        status: FlagsPermissionBooking.permission_instant_booking_create.isEnabled(),
        title: "Booking Instan",
        icon: "booking-instant",
        action: this.validationAccount ? this.openTncModal : {}
      }
    ];

    const result = permissions.filter((item: any) => item.status);
    return {
      data: result,
      title: result[0]?.title,
      action: result[0]?.action,
      total: result.length
    };
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  balanceUnderLimit = false;
  get isBalanceUnderLimitForPos() {
    return (
      BalanceController.balanceData.walletBalance <
        this.dataProfile.limit_rule_min_balance &&
      this.dataProfile.account_type === "partner"
    );
  }
  async goToCreate() {
    this.menuFloat = false;
    if (this.isBalanceUnderLimitForPos) {
      this.balanceUnderLimit = true;
      return;
    }
    await this.$router.push("/shipment/booking/create");
    this.$router.push("/shipment/booking/create");
    dataLayer("floating_button_booking_manual", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }

  goToCreateClientBook() {
    this.menuFloat = false;
    this.$router.push("/shipment/booking/create-client");
    dataLayer("floating_button_booking_client", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }

  goToCreateShipmentBook() {
    this.menuFloat = false;
    this.$router.push(`/shipment/booking/create/by-shipment-id`);
    dataLayer("floating_button_booking_shipment", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }

  goToInstantBooking() {
    this.menuFloat = false;
    this.tncModal = false;
    InstantBookingController.setIsInstantBookingTncConfirmed(true);
    this.$router.push("/shipment/booking/create/instant-booking-shipment");
    dataLayer("floating_button_booking_instant", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }

  onCloseAnywhere(value: boolean) {
    this.menuFloat = value;
  }

  get validationAccount() {
    return AccountController.validationAccount;
  }

  openTncModal() {
    this.tncModal = true;
  }

  onCloseTncModal() {
    this.tncModal = false;
  }
}
